<script>
    import { cloudImageBaseUrl } from '@/CONF.js'
    
    import { bank_list } from "@/api/payment";
    import { onLineCanal, canUsePaymentType, offline_payment_order, onLineRecharge } from "@/api/recharge";
    import Clipboard from "clipboard";
    export default {
        name:"Recharge",
        components: {
            Header: () => import('components/base/Header'),

            RechargeForm:() => import('components/template-3/RechargeForm'),
            RechargeFormOnline:() => import('components/template-3/RechargeFormOnline'),

            MoneyList: () => import('./MoneyList.vue'),
            MoneyTEMPLATE1: () => import('./MoneyTEMPLATE1.vue'),
        },
        data() {
            return {
                popup: false,
                onLine: [],
                type: JSON.parse(self.localStorage.getItem('user')).type,

                /* template-2 begin */
                header:{
                    mainTitle:getWord('recharge')
                },
                username:JSON.parse(self.localStorage.getItem('user')).username,
                channelsData:null,
                currentType:{
                    id:null,
                    index:0,
                    title:null,
                    bg:null,
                },
                currentSelectPay:{
                    id:null,
                    item:null,
                    amount:null,
                    remitter:null,
                    bankcardId:null,
                    need_params:{},
                    bankcardName:null,
                    remittanceMethod:null,
                },
                rechargeType:'underline',
                checkedIcon:require('@@/assets/images/recharge/icons/checked.png'),
                rechargeAmount:[
                    {
                        amount:50,
                        checked:false,
                    },
                    {
                        amount:100,
                        checked:false,
                    },
                    {
                        amount:500,
                        checked:false,
                    },
                    {
                        amount:1000,
                        checked:false,
                    },
                    {
                        amount:2000,
                        checked:false,
                    },
                    {
                        amount:3000,
                        checked:false,
                    },
                    {
                        amount:4000,
                        checked:false,
                    },
                    {
                        amount:5000,
                        checked:false,
                    },
                ],
                sheet:{
                    bankcard:false,
                    remittanceMethod:false,
                },                
                bankcardSheetData: null,
                transferMethodData: [
                    {
                        id: 1,
                        name: getWord(['online_bank', 'transfer']),
                        method: this.transferMethodValue,
                    },
                    {
                        id: 2,
                        name: getWord('bank_teller'),
                        method: this.transferMethodValue,
                    },
                    {
                        id: 3,
                        name: getWord('mobile_banking'),
                        method: this.transferMethodValue,
                    },
                    {
                        id: 4,
                        name: getWord('atm'),
                        method: this.transferMethodValue,
                    }
                ],
                imgTrue:{
                    tf:false,
                    url:'',
                },//二维码图片
                listType:0,//支付类型
                size:200,//二维码大小
                cloudImageBaseUrl,
                /* template-2 end */

                backTo:'/p',

                ossBankUrl:'https://cpfiles.'+process.env.VUE_APP_PROD_DOMAIN+'/bank',
            };
        },
        methods: {
            finish(data){
                this.$router.push({
                    path: '/money/finish',
                    query: data,
                });
            },
            changeType(item,typeClick){
                var that=this;

                Object.keys(this.currentSelectPay).forEach(item=>{
                    if (item!=='need_params') {
                        this.currentSelectPay[item]=null;
                    }
                })               
                this.channelsData.forEach(item=>{
                    item.checked=false;
                }) 
                item.checked=true;
                this.currentType.id=item.id;
                this.currentType.title=item.name;
                this.currentType.index=this.channelsData.findIndex(_item=>{
                    return _item.id===item.id
                });

                if(that.channelsData[that.currentType.index].canal[that.rechargeType]) {
                    this.$nextTick(function(){
                        this.select(that.channelsData[that.currentType.index].canal[that.rechargeType][0],that.channelsData[that.currentType.index].canal[that.rechargeType]);    
                    })
                }

                if (this._TEMPLATE==='template-3' && typeClick) {
                    this.$router.push({
                        path:'/money',
                        query:{
                            type:this.currentType.title
                        }
                    })
                    this.header.mainTitle=this.currentType.title+getWord('deposit')
                }
            },
            formatBankcard(numbers){
                var result=''
                numbers.split('').forEach( (item,index)=>{
                    if (index % 4 == 0 && index != 0) {
                        result+=" "+item;
                    }else {
                        result+=item
                    }
                } )
                return result;
            },
            select(item, result, justCheck) {
                var related=result.filter(item=>{
                    return item.checked    
                })
                if (related.length>0) {
                    related.forEach(item=>{
                        item.checked=false;
                    })
                }
                item.checked=true;                

                if (justCheck) {
                    this.currentSelectPay.amount=item.amount
                }else {
                    this.currentSelectPay.item=item;    
                    this.currentSelectPay.id=item.id;
                }
            },
            formSubmit(data,type){
                let paramsKey = Object.keys(data.data);
                if (!paramsKey || paramsKey.length <= 0) {
                    app.Mint.Toast(getWord('no_result5'));
                    return false;
                }
                let form1 = document.createElement("form");
                form1.id = "form1";
                form1.name = "form1";
                document.body.appendChild(form1);
                for (let i = 0; i < paramsKey.length; i++) {
                    let inputItem = document.createElement("input");
                    inputItem.type = "hidden";
                    inputItem.name = paramsKey[i];
                    inputItem.value = data.data[paramsKey[i]];
                    form1.appendChild(inputItem);
                }
                form1.method = type;
                //提交路径
                form1.action = data.url;
                //执行提交
                form1.submit();
                // 删除该 form
                document.body.removeChild(form1);
            },
            onlineOrder(){
                onLineRecharge({
                    canal_id:this.currentSelectPay.id,
                    money:this.currentSelectPay.amount,
                    ...this.currentSelectPay.need_params,
                }).then(result => {
                    if (result.data.code == "SUCCESS") {
                        var data=result.data.result;

                        if(data.type == 1){//post表单
                            this.formSubmit(data,'POST');
                        }
                        if(data.type == 2){//get表单
                            this.formSubmit(data,'GET');
                        }
                        if(data.type == 3){//生成二维码
                            this.imgTrue.tf = true;
                            this.imgTrue.url = data.url;
                            this.listType = 3;
                        }
                        if(data.type == 4){//二维码图片
                            this.imgTrue.tf = true;
                            this.imgTrue.url = data.url;
                        }
                        if(data.type == 5){//跳转
                            window.location.href = data.url;
                        }
                        if(data.type == 6){//HTML                            
                            document.write(data.data);
                        }
                        if(data.type == 7){//弹窗
                            this.finish(data.data);
                        }
                    }else {
                        app.Mint.Toast(result.data.msg);
                    }
                });
            },
            offlineOrder(){
                if (!this.currentSelectPay.item) {
                    app.Mint.Toast(getWord('fill_payment_account'));
                    return false;
                }
                if (!this.currentSelectPay.remitter) {
                    app.Mint.Toast(getWord(['fill', 'username3']));
                    return false;
                }
                if (!this.currentSelectPay.amount) {
                    app.Mint.Toast(getWord(['fill', 'transfer2', 'amount_of_betting']));
                    return false;
                }
                if (!this.currentSelectPay.remittanceMethodId&&this.currentType===0) {
                    app.Mint.Toast(getWord('fill_transfer_channel'));
                    return false;
                }
                offline_payment_order(
                    this.currentSelectPay.item.type,
                    this.currentSelectPay.remitter,
                    this.currentSelectPay.amount,
                    this.currentSelectPay.item.account_num,
                    this.currentSelectPay.item.payee,
                    this.currentType.id===6?'1':this.currentSelectPay.remittanceMethodId,
                    this.currentType.id===6?this.currentSelectPay.bankcardName:this.currentSelectPay.item.bank_id
                ).then(result => {
                    if (result.data.code == "SUCCESS") {
                        // app.Mint.Toast('已提交申请，请耐心等待管理员审核');
                        this.$router.push({ path: "/money/payment_page/"+ this.currentSelectPay.amount });
                    }else{
                        app.Mint.Toast(result.data.msg);
                    }
                })
            },
            bankcardSheetValue(item, index) {
                this.currentSelectPay.bankcardId=item.id;
                this.currentSelectPay.bankcardName=item.name;                
            },
            transferMethodValue(item, index) {
                this.currentSelectPay.remittanceMethod=item.name;
                this.currentSelectPay.remittanceMethodId=index-0+1;
            },
            async copyText() {
                let clipboard = new Clipboard(".copy");
                clipboard.on("success", function(e) {
                    if (clipboard) {
                        clipboard.destroy();
                    }
                });
                app.Mint.Toast(getWord('clipboard_done'));
            },
            changeLine(item){
                var that=this;
                Object.keys(this.currentSelectPay).forEach(item=>{
                    if (item!=='need_params') {
                        this.currentSelectPay[item]=null;
                    }
                })   
                this.rechargeType=item;

                this.changeType(this.channelsData[that.currentType.index])

            }
        },
        created(){
            console.log(app.Mint)
        },
        computed: {
            userBalance(){
                return this.$store.state.userBalance && this.$store.state.userBalance['user_money'];
            },
        },
        mounted() {
            var that=this;
            if (_TEMPLATE!=='template-3') {
                this.$root.$emit('closeBottomTab', false)
            }

            onLineCanal().then(result => {
                if (result.data.code == "SUCCESS") {
                    this.onLine = result.data.result;
                }else {
                    app.Mint.Toast(result.data.msg);
                }
            })

            canUsePaymentType().then(result => {
                if (result.data.code == "SUCCESS") {
                    var _bankcardSheetData=[];
                    result.data.result.forEach( (item,index)=>{
                        if(index===0) {
                            item.checked=true;
                        }else {
                            item.checked=false;    
                        }
                        switch(item.id) {
                            case 1:
                                item.icon=require('@@/assets/images/recharge/icons/china-union-pay.png')
                                item.bg=require('@@/assets/images/recharge/typebg/china-union-pay.png')
                                break;
                            case 2:
                                item.icon=require('@@/assets/images/recharge/icons/ali-pay.png')
                                item.bg=require('@@/assets/images/recharge/typebg/ali-pay.png')
                                break;
                            case 3:
                                item.icon=require('@@/assets/images/recharge/icons/wechat-pay.png')
                                item.bg=require('@@/assets/images/recharge/typebg/wechat-pay.png')
                                break;
                            case 4:
                                item.icon=require('@@/assets/images/recharge/icons/tencet-pay.png')
                                item.bg=require('@@/assets/images/recharge/typebg/tencent-pay.png')
                                break;
                            case 6:
                                item.icon=require('@@/assets/images/recharge/icons/others-pay.png')
                                item.bg=require('@@/assets/images/recharge/typebg/others-pay.png')
                                break;    
                            default:
                                break;
                        }
                        item.canal.online&&item.canal.online.forEach(item=>{
                            item.checked=false;
                            if (item.need_params && item.need_params.length>0) {
                                item.need_params.forEach(_item=>{
                                    this.currentSelectPay.need_params[_item.key]=null
                                })
                            }
                        })
                        item.canal.underline&&item.canal.underline.forEach(item=>{
                            item.bank_name&&_bankcardSheetData.push({
                                id: item.bank_id,
                                method: that.bankcardSheetValue, 
                                name: item.bank_name, 
                            })
                            item.checked=false;
                        })
                    })                    
                    bank_list().then(result => {
                        if (result.data.code == "SUCCESS") {
                            result.data.result.forEach(item=>{
                                _bankcardSheetData.push({
                                    id: item.id,
                                    method: that.bankcardSheetValue, 
                                    name: item.bank, 
                                })
                            });
                            _bankcardSheetData = _bankcardSheetData.filter((item, index, self) => self.findIndex(_item => _item.name === item.name) === index);
                            this.bankcardSheetData=_bankcardSheetData;
                        }
                    });
                    this.channelsData = result.data.result;
                    window.localStorage.setItem('channelsData',JSON.stringify(result.data.result));
                    if (this._TEMPLATE!=='template-3') {
                        this.currentType.keycode=this.channelsData[0].keycode;
                        this.currentType.title=this.channelsData[0].name;
                        this.currentType.id=this.channelsData[0].id;
                        this.currentType.index=0;
                    }else {

                    }

                    if(that.channelsData[that.currentType.index].canal[that.rechargeType]) {
                        this.$nextTick(function(){
                            this.select(that.channelsData[that.currentType.index].canal[that.rechargeType][0],that.channelsData[that.currentType.index].canal[that.rechargeType]);    
                        })                    
                    }
                }else {
                    app.Mint.Toast(result.data.msg);
                }
            })
        },
        // provide: function(){
        //     return {
        //         'channelsData': this.channelsData,
        //     }
        // },
        beforeRouteLeave(to, from, next){
            if (_TEMPLATE!=='template-3') {
                this.$root.$emit('closeBottomTab', true)
            }
            next();
        },
        beforeRouteUpdate (to, from, next) {
            if (!to.query.type && this._TEMPLATE==='template-3') {
                this.header.mainTitle= getWord('recharge');
                this.currentType.title=null;
                this.currentType.index=0;
            }
            next();
        },
    };
</script>
<template>
    <section id="recharge" :class="_TEMPLATE">
        <template v-if="_TEMPLATE==='template-1'">
            <div class="money_bg"></div>
            <MoneyTEMPLATE1 v-if="channelsData"
                :onLine="onLine"
                :channelsData="channelsData"
            />
        </template>
        <template v-if="_TEMPLATE==='template-2'&&channelsData">
            <Header v-if="_TEMPLATE==='template-2'" 
                :mainTitle="header.mainTitle" 
                :backButton=true
            />
            <div class="type">
                <h6>{{ getWord(['recharge','account']) }}：<span>{{username}}</span></h6>
                <ul>
                    <li v-for="item in channelsData" @click="changeType(item)" :class="{active:item.checked}">
                        <img :src="item.icon" />
                        {{ getWord(item.keycode) }}
                    </li>
                </ul>
                <nav v-if="currentType && currentType.id!==6">
                    <!-- <i class="iconfont icon-recharge"></i> -->
                    <img :src="require('@@/assets/images/recharge/icons/rmb.png')" />
                    <a v-if="channelsData[currentType.index].canal&&channelsData[currentType.index].canal.underline" :class="{highlight:rechargeType==='underline'}" @click="changeLine('underline')">{{ getWord(currentType.keycode) }} {{ getWord('transfer') }}</a>
                    <a v-if="channelsData[currentType.index].canal&&channelsData[currentType.index].canal.online" :class="{highlight:rechargeType==='online'}" @click="changeLine('online')">{{ getWord(currentType.keycode) }} {{ getWord('goto') }}</a>
                    <h6>{{ getWord('transfer_rules') }}</h6>
                </nav>
            </div>
        </template>
        <template v-if="_TEMPLATE==='template-3'&&channelsData">
            <Header
                :mainTitle="header.mainTitle" 
                :backButton=false
                :backTo="backTo"
            />
            <div class="type" v-if="!currentType.title">
                <h6>
                    <b>{{ getWord('account') }}：<span>{{username}}</span></b>
                    <b>{{ getWord('balance') }}：<span>{{userBalance}}</span></b>
                </h6>
                <h3>{{ getWord('transfer3') }}</h3>
                <ul>
                    <li v-for="item in channelsData" @click="changeType(item,true)">
                        {{ getWord(item.keycode) }} {{ getWord('deposit') }}
                        <i class="iconfont icon-back"></i>
                    </li>
                </ul>
            </div>
            <div class="recharge" v-if="currentType.title">
                <nav>
                    <a :class="{highlight:rechargeType==='underline'}" @click="changeLine('underline')">{{currentType.title}} {{ getWord('transfer') }}</a>
                    <a :class="{highlight:rechargeType==='online'}" @click="changeLine('online')">{{currentType.title}} {{ getWord('goto') }}</a>
                    <h6>{{ getWord('transfer_rules') }}</h6>
                </nav>
            </div>
        </template>
        <template v-if="(_TEMPLATE==='template-2'||_TEMPLATE==='template-3')&&channelsData && currentType.title">
            <div class="content" v-if="rechargeType==='underline'&&channelsData[currentType.index].canal&&channelsData[currentType.index].canal.underline">
                <h4><span>1</span>{{ getWord('confirm_and_transfer') }}</h4>

                <div class="accounts" v-if="channelsData" :class="{'type2':channelsData[currentType.index].id===2||channelsData[currentType.index].id===3}">
                    <div class="inner">
                        <div class="account" 
                            @click='select(item,channelsData[currentType.index].canal[rechargeType])'
                            :style="item.type===1
                            ?{
                                'background-image':'url('+ossBankUrl+item.background_pic+')',
                                '--data-icon-checked':'url('+checkedIcon+')',
                            }
                            :{
                                'background-image':'url('+channelsData[currentType.index].bg+')',
                                '--data-icon-checked':'url('+checkedIcon+')',
                            }" 
                            v-for="item in channelsData[currentType.index].canal[rechargeType]"
                            :class="{
                                'type1':item.type===1||item.type===6,
                                'checked':item.checked
                            }"
                            >
                            <label v-if="_TEMPLATE==='template-3'">{{formatBankcard(item.account_num)}}</label>

                            <template v-if="_TEMPLATE==='template-2'">
                                <img class="logo" :src="item.logo?ossBankUrl+item.logo:' '" v-if="item.type===1" />
                                <img :src="item.pic?cloudImageBaseUrl+item.pic:'www.baidu.com'" v-if="item.type!==1 && item.type!==6" />
                                <h5 v-if="item.type!==1 && item.type!==6">{{ getWord(['copy2','qrcode_receive']) }}</h5>
                                <h5 v-else :class="{'active':item.checked}">
                                    <template v-if="_TEMPLATE==='template-2' && (item.type===1||item.type===6)">
                                        {{ item.bank_name }}
                                    </template>
                                    <template v-if="_TEMPLATE==='template-2' && item.type===6">
                                        <br />{{ item.subbranch }}
                                    </template>
                                    <span>{{item.payee}}</span>
                                </h5>
                                <h6>{{item.type!==1?getWord('username4')+':':''}}{{formatBankcard(item.account_num)}} <a class="copy" :data-clipboard-text="item.account_num" @click="copyText()">{{ getWord('copy') }}</a></h6>
                            </template>
                            
                            <RechargeForm v-if="item.checked" :class="_TEMPLATE"
                                :currentSelectPay='currentSelectPay'
                                :currentType='currentType'
                                :sheet='sheet'
                            />
                        </div>
                    </div>
                </div>

                <h4><span>2</span>{{ getWord('fill_offline_transfer_info') }}</h4>

                <div class="form" v-if="_TEMPLATE==='template-2'">
                    <fieldset v-if="currentType.id===1">
                        <label>{{ getWord('bank_name3') }}</label>
                        <input type="text" :placeholder="getWord('fill_bank2')" @click="sheet.bankcard=true" :value="currentSelectPay.bankcardName" />
                    </fieldset>
                    <fieldset v-if="currentType.id===6">
                        <label>{{ getWord('bank_name3') }}</label>
                        <input type="text" :placeholder="getWord('416')" v-model="currentSelectPay.bankcardName" />
                    </fieldset>
                    <fieldset>
                        <label>{{ getWord('username3') }}</label>
                        <input type="text" :placeholder="getWord(['fill', 'username3'])" v-model="currentSelectPay.remitter" />
                    </fieldset>
                    <fieldset>
                        <label>{{ getWord(['transfer2', 'amount_of_betting']) }}</label>
                        <input type="text" :placeholder="getWord(['fill', 'transfer2', 'amount_of_betting'])" v-model="currentSelectPay.amount" />
                    </fieldset>
                    <fieldset v-if="currentType.id===1">
                        <label>{{ getWord('transfer_address') }}</label>
                        <input type="text" :placeholder="getWord('fill_transfer_channel')" @click="sheet.remittanceMethod=true" :value="currentSelectPay.remittanceMethod" />
                    </fieldset>

                    <button class="submit" @click="offlineOrder()">{{ getWord(['done', 'transfer', 'submit', 'deposit', 'apply_for']) }}</button>
                </div>
            </div>
            <div class="content" v-if="rechargeType==='online'&&channelsData[currentType.index].canal&&channelsData[currentType.index].canal.online">
                <ul class="shop">
                    <li 
                        v-for="item in channelsData[currentType.index].canal[rechargeType]"
                        @click="select(item,channelsData[currentType.index].canal[rechargeType])"
                        :class="{highlight:item.checked}"
                        >
                        <label><span class="radio"></span>{{item.title}}</label>

                        <RechargeFormOnline v-if="item.checked" :class="_TEMPLATE"
                            :currentSelectPay='currentSelectPay'
                            :rechargeAmount="rechargeAmount"
                        />
                    </li>
                </ul>
                <h6>{{ getWord(['recharge', 'amount_of_betting']) }}</h6>
                <ul class="amount">
                    <li 
                        v-for="item in rechargeAmount"
                        @click="select(item,rechargeAmount,true)"
                        :class="{highlight:item.checked}"
                        >
                        {{item.amount}}
                    </li>
                </ul>

                <div class="form" v-if="_TEMPLATE==='template-2' && currentSelectPay && currentSelectPay.item && currentSelectPay.item.min">
                    <div class="fieldset" v-if="currentSelectPay.item.need_params&&currentSelectPay.item.need_params.filter(item=>{return item.type===2})">
                        <label>{{ getWord('amount_of_betting') }}</label>
                        <input type="number" :placeholder="getWord(['recharge', 'amount_of_betting', 'is'])+currentSelectPay.item.min+'~'+currentSelectPay.item.max" v-model="currentSelectPay.amount" />
                    </div>
                    <input type="number" :placeholder="getWord(['recharge', 'amount_of_betting', 'is'])+currentSelectPay.item.min+'~'+currentSelectPay.item.max" v-model="currentSelectPay.amount" v-else />

                    <div class="fieldset" v-if="currentSelectPay.item.need_params&&currentSelectPay.item.need_params.filter(item=>{return item.type===2})" v-for="item in currentSelectPay.item.need_params&&currentSelectPay.item.need_params.filter(item=>{return item.type===2})">
                        <label>{{ item.name_text }}</label>
                        <input v-if="item.input_type=='text'" type="text" v-model="currentSelectPay.need_params[item.key]" />
                        <select v-if="item.input_type=='select'" v-model="currentSelectPay.need_params[item.key]">
                            <option v-for="_item in item.select_value" :value="_item.key">{{ _item.name }}</option>
                        </select>
                    </div>
                    
                    <button class="submit" @click="onlineOrder()">{{ getWord('recharge') }}</button>
                </div>

                <img v-if="imgTrue.tf && listType != 3" :src="imgTrue.url" class="width:6rem;height:6rem;border:1px solid #CCC;margin:0.3rem 0 0 0.65rem;" />
                <qriously v-else-if="imgTrue.tf && listType == 3" :value="imgTrue.url" :size='size' style='text-align:center;margin:0;padding:0;'/>
            </div>
            
            <div class="tips" v-if="currentType && currentType.id!==6">
                {{ getWord('tips') }}：<br /><br />
                <div v-html="channelsData[currentType.index][rechargeType+'_remark']"></div>
            </div>

            <div class="buttons" v-if="_TEMPLATE==='template-3'">
                <button class="submit" @click="offlineOrder()" v-if="rechargeType==='underline'">{{ getWord(['confirm3','submit']) }}</button>
                <button class="submit" @click="onlineOrder()" v-if="rechargeType==='online'">{{ getWord(['confirm3','submit']) }}</button>
            </div>
            <MoneyList
                :transferMethodData="transferMethodData"
                :bankcardSheetData="bankcardSheetData"
                :sheet="sheet"
            />
        </template>        
    </section>
</template>
<style lang="scss" scoped>
@import "@@/assets/style/_variables";
    #recharge {
        overflow: auto;
        overflow-x: hidden;

        &.template-3 {
            height: 100%;
            background-color: #F5F5F9;

            .type {

                h3 {
                    font-size: .3rem;
                    font-weight: normal;
                    padding: .25rem;
                    color: #B0B0B0;
                    background-color: #F5F5F9;
                }

                h6 {
                    display: flex;
                    justify-content: space-around;
                    padding:.25rem;
                    background-color: #ffffff;

                    b {
                        font-size: .3rem;
                        font-weight: normal;
                        color: #999999;

                        span {
                            color: #EC2829;
                        }
                    }
                }

                ul {
                    background-color: #ffffff;

                    li {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin: 0 .25rem;
                        padding: .25rem 0;
                        border-top: 1px solid #d0d0d0;
                        font-size: .3rem;
                        color: #5F646E;

                        &:first-child {
                            border-top:0;
                        }

                        .icon-back {
                            display: inline-block;
                            transform: rotateZ(180deg);
                            opacity: .4;
                        }
                    }
                }
            }

            .recharge {

                nav {

                    a {
                        width: 50%;
                        background-color: #ffffff;
                        display: inline-block;
                        color: #5F646E;
                        text-align: center;
                        font-size: .36rem;
                        border-bottom: 2px solid transparent;
                        padding:.25rem 0;

                        &.highlight {
                            border-color: transparent;
                            color: #EC2829;
                            border-color: #EC2829;
                        }
                    }

                    h6 {
                        color: #EC2829;
                        background-color: #ffffff;
                        font-size: .28rem;
                        font-weight: normal;
                        margin-top: .25rem;
                        padding: .1rem .25rem;
                    }
                }
            }

            .content {
                background-color: transparent;
                padding:0;
                
                > h4,
                > h6 {
                    display: none;
                }

                .accounts {
                    margin:0;
                    background-color: #ffffff;
                    padding: 0 .25rem;

                    &.type2 {

                       .inner {

                            .account {

                                &:before {
                                    content:none;
                                }

                                &.checked {

                                    label {

                                        &:before {
                                            border-color: #EC2829;
                                        }
                                    }
                                }

                                > label {
                                    display: block;
                                    margin-top: -.125rem;
                                    padding-bottom: .25rem;

                                    &:before {
                                        border: 1px solid #B0B0B0;
                                        border-radius: 100%;
                                        content: '';
                                        position: absolute;
                                        right: 0;
                                        background-size: contain;
                                        background-repeat: no-repeat;
                                        background-image: var(--data-icon-checked);
                                        width: 0.4rem;
                                        height: 0.4rem;
                                        border-color: #B0B0B0;
                                        top: 0.2rem;
                                    }    
                                }

                                .form {
                                    margin: 0 -.5rem;
                                }
                            }
                       } 
                    }

                    .inner {
                        white-space: normal;

                        .account {
                            margin-left: 0;
                            background-image: none!important;
                            height: auto;
                            display: block;
                            width: auto;
                            padding: .3rem .25rem 0 .25rem;

                            &.checked {
                                padding-bottom: 0!important;

                                &:before {
                                    width: .4rem;
                                    height: .4rem;
                                    border-color: #EC2829;
                                    top: .2rem;
                                }

                                &:after {
                                    background-color: #EC2829;
                                }
                            }

                            &:before {
                                content: "";
                                background-image:none;
                                border:1px solid #B0B0B0;
                                border-radius: 100%;
                                width: .4rem;
                                height: .4rem;
                                top:.2rem;
                                right: 0;
                                position: absolute;
                            }

                            &:after {
                                content:'';
                                width: 0.2rem;
                                height: 0.2rem;
                                background-color: #B0B0B0;
                                display: block;
                                border-radius: 100%;
                                position: absolute;
                                right: .12rem;
                                top: .32rem;
                            }

                            h5,h6 {
                                color: #5F646E;
                            }

                            h5 {
                                margin-bottom: 0;

                                &.active {
                                    padding-bottom: .25rem;
                                }
                            }

                            &.type1 {
                                padding:.2rem 0;
                                border-bottom:1px solid #d0d0d0;

                                &.checked {
                                    border-bottom: 0;

                                    > label {
                                        padding-bottom: .25rem;
                                        display: block;
                                    }
                                }

                                &:nth-of-type(1) {
                                    border-top:0;
                                }

                                h5 {
                                    margin-left: 0;

                                    span {
                                        font-size: .3rem;
                                    }
                                }

                                h6 {
                                    display: none;
                                }
                            }
                        }
                    }
                }

                .form {

                    .fieldset {
                        display: flex;
                        align-items: center;
                        padding: 0;
                        border:0;

                        &:first-child {
                            border-top:0;
                        }

                        label {
                            width: 1.8rem;
                            font-size: .28rem;
                            line-height: 1;
                        }

                        input {
                            width: 4.7rem;
                            font-size: .28rem;
                            line-height: 2;
                            padding: 0.05rem .25rem;
                            box-sizing: border-box;

                            &:disabled {
                                background-color: transparent;
                                width: 3.3rem;
                            }
                        }

                        > h6 {
                            width: .4rem;
                            padding: 0 .25rem;

                            .iconfont {
                                color: #EC2829;
                            }

                            img {
                                height: .3rem;
                                vertical-align: middle;
                            }
                        }

                        > div {
                            border-bottom: 1px solid #d0d0d0;
                            width: 100%;
                            padding: 0.1rem 0;
                            position: relative;

                            &.standalone {
                                border-top: 1px solid #d0d0d0;

                                label {
                                    color: #5F646E;
                                }
                            }

                            &.select {                                

                                .icon-back {
                                    position: absolute;
                                    top:50%;
                                    transform: rotateZ(270deg) translateY(-.5rem) translateX(50%);
                                    transform-origin: center;
                                    opacity: .4;
                                }
                            }
                        }

                        .copy {
                            background-color: #EDB93F;
                            display: inline-block;
                            font-size: .28rem;
                            color: #ffffff;
                            text-align: center;
                            white-space: nowrap;
                            padding: .14rem .45rem;
                            border-radius: 3px;
                            position: absolute;
                            right: .1rem;
                        }
                    }
                }

                ul {

                    &.amount {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        padding: .25rem;

                        li {
                            margin:0;
                            margin-bottom: .125rem;
                        }
                    }

                    &.shop {
                        padding-top: 0;
                        margin-bottom: 0;

                        li {
                            border-bottom:1px solid #d0d0d0;
                            display: block;
                            padding: .2rem .25rem;
                            background-color: #ffffff;
                            color: #5F646E;

                            &:first-child {
                                border-top:0;
                            }

                            &.highlight {
                                color: #5F646E;
                                padding-bottom: 0;

                                label {
                                    padding-bottom: .2rem;

                                    .radio {
                                        border-color: #EC2829;

                                        &:before {
                                            width: 0.2rem;
                                            height: 0.2rem;
                                            background-color: #EC2829;
                                        }
                                    }
                                }
                            }

                            label {
                                display: flex;
                                justify-content: space-between;
                                flex-direction: row-reverse;

                                .radio {
                                    background-color: transparent;
                                    width: 0.4rem;
                                    height: 0.4rem;

                                    &:before {
                                        width: 0.2rem;
                                        height: 0.2rem;
                                        content: '';
                                        background-color: #666666;
                                        border-radius: 100%;
                                        position: absolute;
                                        top: 0;
                                        right: 0;
                                        left: 0;
                                        bottom: 0;
                                        margin: auto;
                                    }
                                }
                            }
                        }
                    }
                }

                > ul {

                    &.amount {
                        display: none;
                    }
                }
            }

            .tips {
                background-color: #ffffff;
                margin-top: 0;
                color: #EC2829;
                padding: .25rem;
            }

            .buttons {

                .submit {
                    background-color: #EC2829;
                    color: #ffffff;
                    display: block;
                    width: 84%;
                    margin: 0.5rem auto;
                    border: 0;
                    font-size: .34rem;
                    padding: .2rem 0;
                    border-radius: 5px;
                }
            }
        }

        &.template-2 {
            background-color: #F5F5F9;

            .type {

                > h6 {
                    background-color: #ffffff;
                    font-size: .3rem;
                    color: #999999;
                    font-weight: normal;
                    border-bottom: 1px solid #F5F5F9;
                    padding: .25rem;

                    span {
                        color: $TEMPLATE2-theme-color;
                    }
                }

                ul {
                    background-color: #ffffff;
                    display: flex;
                    list-style: none;
                    padding: .25rem .25rem .5rem;
                    margin-bottom: .2rem;

                    li {
                        flex-basis: 17%;
                        text-align: center;
                        font-size: .28rem;

                        background-color: transparent;
                        border: 1px solid transparent;
                        padding: .1rem;

                        &.active {
                            background-color: rgba(67, 147, 255, 0.3);
                            border: 1px solid rgba(67, 147, 255, 1);                            
                        }

                        img {
                            margin:0 auto;
                            margin-bottom: .1rem;
                            width: .8rem;
                            height: .8rem;
                            display: block;
                        }
                    }
                }

                nav {
                    background-color: #ffffff;
                    padding:.25rem;
                    padding-bottom: 0;

                    > i {
                        font-size: 0.35rem;
                        color: $TEMPLATE2-theme-color;
                    }

                    > img {
                        width: .3rem;
                        vertical-align: -1px;
                    }

                    a {
                        display: inline-block;
                        color: $TEMPLATE2-theme-color;
                        font-size: .3rem;
                        padding:.1rem .2rem;
                        border: 1px solid $TEMPLATE2-theme-color;
                        margin-left: .2rem;

                        &.highlight {
                            border-color: transparent;
                            color: #ffffff;
                            background-color: $TEMPLATE2-theme-color;
                        }
                    }

                    h6 {
                        color: $TEMPLATE2-theme-color;
                        font-size: .24rem;
                        font-weight: normal;
                        margin: .1rem 0 0 .5rem;
                    }
                }
            }

            .content {

                .form {

                    .fieldset {
                        display: flex;
                        align-items: center;

                        input {
                            width: 98%;
                            border: 1px solid #cccccc;
                            padding: .25rem;
                            box-sizing: border-box;

                            &::placeholder {
                               color: #cccccc;
                            }
                        }

                        select {
                            width: 98%;
                            border: 1px solid #cccccc;
                            padding: .25rem;
                        }
                    }
                }

                .accounts {

                    .inner {

                        .account {

                            h5 {

                                span {
                                    display: block;
                                }
                            }

                            img {

                                &.logo {
                                    width: 0.8rem;
                                    height: 0.8rem;
                                }
                            }
                        }
                    }
                }
            }
        }

        .content {
            padding:.25rem;
            background-color: #ffffff;
            color: #333333;
            font-size: .3rem;

            > h6 {
                font-size: .24rem;
                margin-left: 1%;
            }

            ul {

                &.shop {
                    border-top: 1px solid #f5f5f9;
                    padding-top: .25rem;
                    margin-bottom: .25rem;

                    li {
                        min-width: 33%;
                        display: inline-block;
                        box-sizing: border-box;
                        padding: 0 .25rem;

                        &.highlight {
                            color: $TEMPLATE2-theme-color;

                            label {

                                .radio {

                                    &:before {
                                        content:'';
                                        width: .12rem;
                                        height: .12rem;
                                        background-color: #666666;
                                        border-radius: 100%;
                                        position: absolute;
                                        top: 0;
                                        right: 0;
                                        left: 0;
                                        bottom: 0;
                                        margin: auto;
                                    }
                                }
                            }
                        }

                        label {

                            .radio {
                                display: inline-block;
                                width:.2rem;
                                height: .2rem;
                                border-radius: 100%;
                                line-height: .2rem;
                                text-align: center;
                                border:1px solid rgba(204,204,204,1);
                                background-color: #DEDEDE;
                                position: relative;
                                margin:0 auto;
                                margin-right: .125rem;
                            }
                        }
                    }
                }

                &.amount {
                    
                    li {
                        width: 23%;
                        display: inline-block;
                        box-sizing: border-box;
                        padding: .075rem .125rem;
                        border: 1px solid #cccccc;
                        text-align: center;
                        margin: 2% 1%;
                        font-size: .36rem;

                        &.highlight {
                            color: $TEMPLATE2-theme-color;
                            border-color: $TEMPLATE2-theme-color;
                        }
                    }
                }
            }

            > h4 {
                margin-bottom: .25rem;
                font-weight: normal;

                span {
                    background-color: $TEMPLATE2-theme-color;
                    display: inline-block;
                    border-radius: 100%;
                    color: #ffffff;
                    width: 0.4rem;
                    text-align: center;
                    font-weight: normal;
                    margin-right: .2rem
                }
            }

            .accounts {
                overflow: auto;
                margin: .25rem 0;

                .inner {
                    white-space: nowrap;

                    .account {
                        display: inline-block;
                        width: 5.5rem;
                        height: 2.5rem;
                        box-sizing: border-box;
                        background-size: cover;
                        margin-left: .25rem;
                        padding: .35rem .25rem;
                        box-sizing: border-box;
                        border-radius: 3px;
                        position: relative;

                        &.checked {

                            &:before {
                                content:'';
                                width: .7rem;
                                height: .7rem;
                                position: absolute;
                                right: 0;
                                top: 0;
                                background-size: contain;
                                background-repeat: no-repeat;
                                background-image: var(--data-icon-checked);
                            }
                        }

                        &:first-child {
                            margin-left:0;
                        }

                        &.type1 {
                            padding: .25rem;

                            h5,
                            h6 {
                                margin-left: 0rem;
                            }

                            h5 {
                                font-size: .3rem;
                                margin-left: 1rem;
                                height: .75rem;

                                span {
                                    font-size: .24rem;
                                    display: block;
                                }
                            }

                            h6 {
                                font-size: .3rem;
                            }
                        }

                        img {
                            width: 1.7rem;
                            height: 1.7rem;
                            float: left;
                            margin-right: .2rem;
                        }

                        h5,
                        h6 {
                            font-weight: normal;
                            color: #ffffff;
                            margin-left: 1.9rem;
                        }

                        h5 {
                            margin-bottom: .35rem;                                
                        }

                        h6 {
                            font-size: .24rem;

                            a {
                                color: #ffffff;
                                font-size: .3rem;
                                border-radius: 2px;
                                padding: .05rem .1rem;
                                border: 1px solid #ffffff;
                                float: right;
                                margin-top: -2px;
                            }
                        }
                    }
                }
            }

            .form {

                button {

                    &.submit {
                        font-size: .3rem;
                        display: block;
                        background-color: $TEMPLATE2-theme-color;
                        color: #fff;
                        border: 0;
                        width: 98%;
                        margin: 0 auto;
                        margin-top: .25rem;
                        padding: .2rem 0;
                    }
                }

                > input {
                    width: 98%;
                    border: 1px solid #cccccc;
                    padding: .25rem;
                    box-sizing: border-box;
                    margin: .25rem 1%;

                    &::placeholder {
                       color: #cccccc;
                    }
                }

                fieldset,
                .fieldset {
                    border:0;
                    border-bottom:1px solid #f5f5f9;
                    font-size: .3rem;
                    padding: .25rem 0;

                    &:first-child {
                        border-top:1px solid #f5f5f9;
                    }

                    label {
                        width: 1.6rem;
                        display: inline-block;
                    }

                    input {
                        font-size: .3rem;
                        border:0;
                        width: 5rem;
                    }

                    select {
                        width: 4.7rem;
                        border: 0;
                        background-color: #ffffff;
                        padding: .15rem;
                    }
                }
            }
        }

        .tips {
            color: #999999;
            font-size: .28rem;
            padding:.1rem .25rem;
            overflow: hidden;

            p {
                padding: 0 .25rem;
            }

            /deep/ ol {
                padding: 0 .25rem;
            }
        }
    }

    .money_bg {
        background-size: 100%;
        width: 7.5rem;
        height: 3.4rem;
        background-image: url('/static/assets/images2/money/back.png');
    }
</style>